<template>
	<v-dialog
		:value="downloadTrackDialogVisible"
		max-width="570"
		@input="$emit('input')">

		<div class="dialog-wrapper download-dialog">
			<div class="dialog-header">
				<h2>{{ isFreeUserPlan() ? 'Free Account' : 'Free Downloads' }}</h2>
			</div>
			<div class="dialog-content">
				<template v-if="(SONG_DOWNLOADED.limit - SONG_DOWNLOADED.downloaded) > 0">
					<p>
						You have <span class="highlight">{{ SONG_DOWNLOADED.limit - SONG_DOWNLOADED.downloaded }}</span> out of
						<span class="highlight">{{ SONG_DOWNLOADED.limit }}</span> free Tracks downloads left today
					</p>
				</template>
				<template v-else>
					<p>
						You've reached your download limit for today
						<span v-if="isFreeUserPlan()" class="note">Try again tomorrow or upgrade to premium</span>
					</p>
				</template>
				<p v-if="DOWNLOAD_SONG_LINK_ERROR" class="error-message">
					{{ DOWNLOAD_SONG_LINK_ERROR }}
				</p>
			</div>
			<div class="dialog-actions">
				<button 
					type="button" 
					class="tetriatary_btn medium" 
					:class="{'center': !isWithinLimit() && !isFreeUserPlan()}"
					@click="cancel()" 
					v-ripple
				>
					{{ !isWithinLimit() && !isFreeUserPlan() ? 'Ok' : 'Cancel' }}
                </button>
				<button 
					v-if="isWithinLimit()" 
					type="button" 
					class="primary_btn medium" 
					@click="download" 
					v-ripple
					:disabled="DOWNLOAD_SONG_LINK_ERROR !== ''"
				>
					Download
				</button>
				<button 
					v-else-if="isFreeUserPlan()" 
					type="button" 
					class="c2a_btn medium" 
					@click="upgrade"
					v-ripple
				>
					Upgrade
					<span class="arrow">
                        <span class="line_svg"></span>
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                        </svg>
                    </span>
				</button>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: "downloadTrack",
	props: ['value'],
	data() {
		return {}
	},
	computed: {
		...mapGetters(['SONG_DOWNLOADED', 'SONG_DOWNLOAD_DATA', 'DOWNLOAD_SONG_LINK', 'DOWNLOAD_SONG_LINK_ERROR', 'PROFILE']),
		downloadTrackDialogVisible: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		},
	},

	methods: {
		...mapActions(['GET_DOWNLOAD_SONG_LINK']),
		cancel() {
			this.downloadTrackDialogVisible = false;
		},
		download() {
            this.GET_DOWNLOAD_SONG_LINK(this.SONG_DOWNLOAD_DATA.id)
				.then(() => {
					if (this.DOWNLOAD_SONG_LINK) {
						this.downloadTrackDialogVisible = false;
						let link = document.createElement('a');
						link.href = this.DOWNLOAD_SONG_LINK;
						link.download = `${this.SONG_DOWNLOAD_DATA.artist} - ${this.SONG_DOWNLOAD_DATA.name}.flac`;
						link.click();
					}
				})
				.catch(err => {
					this.downloadTrackDialogVisible = true;
					console.error(`Error downloadTrack: ${err}`)
				})
		},
		upgrade() {
			this.$router.push({name: 'memberships'});
		},
        isFreeUserPlan() {
            return this.PROFILE === 'free';
        },
        isWithinLimit() {
            return (this.SONG_DOWNLOADED.limit - this.SONG_DOWNLOADED.downloaded) > 0
        }
	}
}
</script>

<style scoped>
button:disabled,
button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	cursor: not-allowed;
	pointer-events: none;
}
button {
    &.center {
        margin: 0 auto;
    }
}
</style>
