<template>
    <v-dialog :value="!connected" max-width="600">
        <div  class="need_login_dialog style_dialog">
            <h2 class="header">
                Oops!
            </h2>
            <div class="cnt">
                Connection interrupted. Reconnecting in {{ secondsRemaining }} seconds...
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'connection-interrupted-timer',
    props: ['value'],
    data() {
        return {
            showTimer: false,
            secondsRemaining: 15,
            intervalId: null,
        };
    },
    mounted() {
        if (!this.isConnected) {
            this.startTimer();
        } else {
            this.stopTimer();
        }
    },
    computed: {
        connected: {
            get() {
                console.log('asdasdasd get',this.value);
                return this.value;
            },
            set(value) {
                console.log('input set', value);
                this.$emit('input', value)
            }
        }
    },
    methods: {
        startTimer() {
            this.showTimer = true;
            this.secondsRemaining = 15;
            this.intervalId = setInterval(() => {
                this.secondsRemaining > 0 ? this.secondsRemaining-- : this.stopTimer();
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.intervalId);
            if (this.isConnected === false) {
                this.showTimer = false;
                return;
            }

            this.checkConnection((status) => {
                this.isConnected = status;

                if (!this.isConnected) {
                    this.startTimer();
                }

                this.connected = this.isConnected;
            })
        },

        checkConnection(callback) {
            let xhr = new (window.ActiveXObject || XMLHttpRequest)("Microsoft.XMLHTTP");

            xhr.onreadystatechange = function() {
                if (xhr.readyState == 4) {
                    if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                }
            };

            try {
                xhr.open("HEAD", process.env.VUE_APP_API_URL, true);
                xhr.send();
            } catch (error) {
                callback(false);
            }
        },
    },
    watch: {
        isConnected(isConnected) {
            isConnected ? this.stopTimer() : this.startTimer();
        },
    },
    beforeDestroy() {
        this.stopTimer();
    },
};

</script>

<style scoped>

</style>
