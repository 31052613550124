<template>
    <v-dialog
        :value="dialog"
        max-width="570"
        @input="$emit('input')"
    >
        <div class="dialog-wrapper warning-change-dialog">
            <div class="dialog-header">
                <h2>Attention</h2>
            </div>
            <div class="dialog-content">
                <p>This action will move your album to draft</p>
            </div>
            <div class="dialog-actions">
                <button type="button" class="red_btn medium" @click="ok()" v-ripple>OK</button>
                <button type="button" class="tetriatary_btn medium" @click="cancel()" v-ripple>Cancel</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "warningChangeAlbum",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        albumID: {
            type: String,
            required: true
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ...mapActions(['CHANGE_ALBUM_TO_DRAFT']),
        ok() {
            this.CHANGE_ALBUM_TO_DRAFT(this.albumID)
                .then(() => {
                    this.dialog = false;
                })
                .catch(err => {
                    console.error('Error changing album to draft:', err);
                });
        },
        cancel() {
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.warning-change-dialog {
    @include dialog((
        has-header: true,
        has-content: true,
        has-actions: true
    ));
}
</style>