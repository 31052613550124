<template>
	<v-dialog
		:value="value" @input="$emit('input')"
		max-width="570">
		<div class="dialog-wrapper error-dialog">
			<div class="dialog-header">
				<h2>Oops!</h2>
			</div>
			<div class="dialog-content bordered">
				Something went wrong
			</div>
			<div class="dialog-actions">
				<button 
					type="button" 
					class="tetriatary_btn medium" 
					@click="dialog = false" 
					v-ripple
				>
					Cancel
				</button>
				<a 
					href="mailto:info.psytrance.network@gmail.com?subject=Found a bug!" 
					class="primary_btn medium" 
					@click="dialog = false" 
					v-ripple
				>
					Report
				</a>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	name: "artistReceivingPayment",
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.error-dialog {
	@include dialog((
		has-header: true,
		has-content: true,
		has-actions: true,
		content-bordered: true
	));
}
</style>
